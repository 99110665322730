@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

.btn:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.text-dl-blue {
  color: #57cc99;
}

.bg-dl-blue {
  background-color: #57cc99 !important;
}

.btn-success-darker {
  color: #fff !important;
  background-color: #38b880 !important;
  border-color: #38b880 !important;
}

.btn-success {
  background-color: #57cc99 !important;
  border-color: #57cc99 !important;
}

.btn-outline-success {
  color: #57cc99 !important;
  border-color: #57cc99 !important;
}

.btn-outline-success:hover {
  background-color: #57cc99 !important;
  border-color: #57cc99 !important;
  color: #fff !important;
}

.btn-outline-primary:hover svg path {
  stroke: #fff;
}

.dropdown-menu.show {
  display: block;
  max-height: 200px;
  overflow: auto;
}

.dash-layout {
  background: #57cc99;
  font-family: 'Lato', sans-serif;
  position: relative;
  min-height: 100vh;
}

.dl-sidebar {
  overflow: auto;
  height: 100vh;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 210px;
  flex: 0 0 210px;
  max-width: 210px;
  min-width: 210px;
  width: 210px;
  padding: 30px 0;
  scrollbar-width: none;
}

.dl-sidebar::-webkit-scrollbar {
  width: 0;
}

.dl-sidebar .routes::-webkit-scrollbar {
  width: 0;
}

#mobile-offcanvas {
  background: #57cc99;
}

.dl-sidebar.mobile {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
  top: 45px;
}

.dl-sidebar .dl-sidebar-menu li a {
  text-decoration: none;
  font-size: 22px;
  color: rgba(255, 255, 255, 0.5);
  padding: 8px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-weight: 500;
}

.dl-sidebar .dl-sidebar-menu li a svg {
  width: 25px;
  margin-right: 20px;
}

.dl-sidebar .dl-sidebar-menu li a:hover,
.dl-sidebar .dl-sidebar-menu li a.active {
  color: #fff;
}

.dl-sidebar .dl-sidebar-menu li a:hover svg path,
.dl-sidebar .dl-sidebar-menu li a.active svg path {
  stroke: #fff;
  /* fill: #fff; */
}

.dl-sidebar .upgrade-plan-btn {
  background: #FFFFFF;
  border-radius: 10px;
  padding: 10px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.025em;
  color: #57cc99;
  text-decoration: none;
  margin-top: 27px;
  width: fit-content;
  margin-left: 30px;
}

.dl-sidebar.mobile .upgrade-plan-btn {
  text-align: center;
  display: block;
}

.dl-sidebar.mobile .dl-sidebar-menu li a {
  padding: 10px 0;
}

.dl-content {
  margin-left: calc(210px - 30px);
  padding: 30px;
}

.dl-content main {
  background-color: #f7fafb;
  border-radius: 60px;
  padding: 20px 45px;
}

.dl-content .routes {
  height: calc(100vh - 195px);
  overflow: auto;
  padding: 1rem 0;
  scrollbar-width: none;
}

/* width */
.dl-content .routes::-webkit-scrollbar {
  width: 0;
}

.dl-shadow-box {
  background-color: #fff;
  border-radius: 30px;
  -webkit-box-shadow: 4px 4px 20px 0 rgba(10, 114, 166, 0.05);
  box-shadow: 4px 4px 20px 0 rgba(10, 114, 166, 0.05);
  padding: 20px;
}

.dl-search-form .form-control::-webkit-input-placeholder {
  color: #c2c6cc;
}

.dl-search-form .form-control::-moz-placeholder {
  color: #c2c6cc;
}

.dl-search-form .form-control:-ms-input-placeholder {
  color: #c2c6cc;
}

.dl-search-form .form-control::-ms-input-placeholder {
  color: #c2c6cc;
}

.dl-search-form i,
.dl-search-form .form-control::placeholder {
  color: #c2c6cc;
}

.dl-search-form .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dl-search-form .form-control:disabled {
  background-color: rgba(248, 249, 250, 1);
}

.dl-nav-icons .badge {
  padding: 2px 5px;
}

.dl-service-status-card p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 600;
}

.dl-service-status-card p div {
  width: 15px;
  height: 15px;
  min-height: 15px;
  max-height: 15px;
  min-width: 15px;
  max-width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dl-service-status-card p.active {
  background-color: #57cc99;
  color: #fff;
}

.dl-service-status-card p.active div {
  background-color: #fff;
}

.dl-service-status-card p.disabled {
  background-color: #f0f0f0;
  color: #a3a3a3;
}

.dl-service-status-card p.disabled div {
  background-color: rgba(163, 163, 163, 0.6);
}

.dl-service-details li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dl-service-details li:not(:last-child) {
  margin-bottom: 20px;
}

.dl-service-details li h5 {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 0;
  margin-right: 10px;
}

.dl-service-details li h5 svg {
  margin-right: 10px;
  width: 25px;
}

.dl-service-details li p {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
}

.dl-service-details li b {
  margin-left: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 25px;
}

.dl-service-details li b.active {
  background-color: #57cc99;
  color: #fff;
}

.dl-service-details li b.deactive {
  background-color: #f0f0f0;
  color: #a3a3a3;
}

.dl-users-chart .dropdown-toggle::after {
  display: none;
}

.dl-table {
  border: none;
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: transparent;
  --bs-table-striped-bg: transparent;
  --bs-table-active-color: transparent;
  --bs-table-active-bg: transparent;
  --bs-table-hover-color: transparent;
  --bs-table-hover-bg: transparent;
}

.dl-table thead tr {
  background-color: #aeffdc;
  border-radius: 30px;
}

.dl-table thead tr th:first-child {
  border-top-left-radius: 15px;
}

.dl-table thead tr th:last-child {
  border-top-right-radius: 15px;
}

.dl-table thead tr th {
  color: #57cc99;
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
}

.dl-table tbody {
  border-top: none !important;
}

.dl-table thead tr,
.dl-table tbody tr {
  vertical-align: middle;
}

.dl-table tbody tr td {
  color: #7c828a !important;
  font-size: 14px;
  border: none;
}

.dl-table tbody tr:nth-of-type(odd) td {
  background-color: rgb(235, 255, 243);
}

.dl-table tbody tr:nth-of-type(even) td {
  background-color: #ffffff;
}

.dl-table tbody tr:last-child td {
  border-width: 0;
}

/* .dl-table tbody tr td:last-child {
    display: flex;
} */

/* .dl-table tbody tr td:last-child .btn:not(:last-child){
    margin-right: 10px;
} */

.dl-table.dl-table-light thead tr {
  background-color: #d7f2fe;
}

.dl-table.dl-table-light thead tr th {
  color: #65ccfb;
}

.btn-dl-edit,
.btn-dl-edit:hover,
.btn-dl-edit:focus {
  background-color: #57cc99;
  color: #fff;
  border-color: #57cc99;
  font-size: 14px;
  font-weight: bold;
}

.btn-dl-remove,
.btn-dl-remove:hover,
.btn-dl-remove:focus {
  background-color: #51bb8d;
  color: #fff;
  border-color: #51bb8d;
  font-size: 14px;
  font-weight: bold;
}

.btn-dl-dark,
.btn-dl-dark:hover,
.btn-dl-dark:focus {
  background-color: #000a5a;
  color: #fff;
  border-color: #000a5a;
  font-size: 14px;
  font-weight: bold;
}

.service-avatar .dl-shadow-box {
  height: 145px;
  width: 60%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.service-avatar.view .dl-shadow-box {
  height: 210px;
  width: 80%;
}

.service-avatar .dl-shadow-box img {
  height: 100px;
  max-height: 100px;
}

.service-avatar .dl-shadow-box button {
  top: -10px;
  right: -5px;
  background-color: #51bb8d;
  border-color: #51bb8d;
}

.edit-preferences .form-label {
  font-size: 18px;
  color: #535353;
}

.edit-preferences .form-control,
.edit-preferences .form-select {
  border-radius: 10px;
}

.jsoneditor {
  border: none !important;
}

.jsoneditor-menu {
  padding: 10px !important;
  border-radius: 20px 20px 0 0;
  height: 50px !important;
  background-color: #beddfa !important;
  border-bottom: none !important;
}

.jsoneditor-menu button {
  color: #57cc99 !important;
}

.jsoneditor-statusbar {
  line-height: 26px !important;
  height: 40px !important;
  color: #808080 !important;
  background-color: transparent !important;
  border-top: 1px solid #ebebeb !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 0 10px !important;
}

.dropzone-ui .dz-ui-footer {
  height: 30px !important;
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
  overflow-wrap: anywhere;
  background-color: #57cc99 !important;
  color: #fff;
  width: 100% !important;
}

.dropzone-ui .dz-ui-header {
  top: 10px !important;
}

.dropzone-ui-extra {
  border: 2px dashed rgba(93, 100, 117, 1) !important;
  background-color: undefined;
  max-height: unset !important;
  min-height: calc(280px + 150px) !important;
}

.dropzone-ui .dz-ui-header {
  position: unset !important;
  margin-bottom: auto !important;
}

.dropzone-ui .dz-ui-footer {
  position: unset !important;
  margin-top: auto !important;
  height: auto !important;
}

.dl-latest-files-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dl-latest-files-list li:not(:last-child) {
  margin-bottom: 15px;
}

.dl-latest-files-list li .btn-area {
  margin-left: auto;
}

.dl-latest-files-list li .btn-area .btn:first-child {
  margin-right: 10px;
}

.dl-latest-files-list li small {
  margin-left: 10px;
}

.dl-latest-files-list li img {
  border-radius: 10px;
  min-height: 40px;
  max-height: 40px;
  min-width: 40px;
  max-width: 40px;
  -o-object-fit: contain;
  object-fit: contain;
}

.sorter i.active {
  color: #222;
}

.dz-ui-label {
  margin-bottom: auto;
}

.swagger-infos-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.swagger-infos-list li:not(:last-child) {
  margin-bottom: 20px;
}

.swagger-infos-list li .title {
  min-width: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.swagger-infos-list li .title h5 {
  margin-bottom: 0;
}

.swagger-infos-list li .content {
  font-size: 20px;
}

.plans-history-modal table thead tr th {
  color: #408458;
}

.plans-history-modal .table thead tr {
  border-bottom: 2px solid #76c89d;
}

.pagination .page-item a {
  color: #51bb8d;
  font-size: 25px;
  line-height: 1;
}

.pagination .page-item.disabled span {
  font-size: 25px;
  line-height: 1;
}

.billing-group-btn .btn {
  border-radius: 0 !important;
}

.cp-logo {
  width: 70px;
  height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 100%;
  object-fit: cover;
}


.cp-logo-layout {
  width: 50px;
  height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 100%;
  object-fit: cover;
}

.list-file-logo {
  object-fit: scale-down;
}

.notif-box {
  display: flex;
  align-items: center;
  padding: 28px 15px;
  gap: 10px;
  min-height: 80px;
  background: #57CC99;
  border-radius: 10px;
}

.notif-box .label {
  width: max-content;
  padding: 3px 5px;
  background: #7ED8B1;
  border-radius: 4px;
  color: #FFF;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 15.6px;
  line-height: 18px;
}

.notif-box .detail {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 400;
  font-size: 16.6px;
  line-height: 21px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}

.notif-box .detail .bi-x{
  cursor: pointer;
}