@import url('https://fonts.googleapis.com/css2?family=Audiowide&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@400;500;600&display=swap');

.error-pages {
    background-color: #EFF8EF;
}

.audiowide {
    font-family: 'Audiowide', cursive;
}

.prompt {
    font-family: 'Prompt', sans-serif;
}

.error-pages .btn.pink {
    background-color: #EFBCAE;
    color: #3B3B3B;
    font-size: 25px;
    padding: 15px 40px;
}

.error-pages h1 {
    font-size: 65px;
}

@media (max-width: 575.98px) {}

@media (min-width: 576px) and (max-width: 767.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .error-pages img {
        width: 75%;
    }
}

@media (min-width: 1200px) {
    .error-pages img {
        width: 75%;
    }
}

@media (min-width: 1400px) {
    .error-pages img {
        width: 100%;
    }
}