/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .cs-p {
    padding: 30px 10px;
  }

  .home_layout{
    display: block;
  }

  .auth_layout {
    background-image: none;
    padding: 30px 0;
  }

  .auth_layout .form_layout {
    border: none;
  }

  .home_layout_nav,
  .home_layout_footer {
    padding: 15px 10px;
  }

  .home_layout .nav-side {
    display: none;
  }

  .home_layout_nav .brand {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .home_layout_nav .brand p {
    margin-top: 15px;
    max-width: 190px;
    text-align: left;
    margin-left: 0;
  }

  .section1 {
    min-height: calc(100vh - 75px);
  }

  .section1 h1 {
    font-size: 60px;
  }

  .section1 p{
    width: 100%;
  }

  .section1 .guide {
    right: 2rem;
    bottom: 3rem;
  }

  .section2 img {
    width: 45%;
  }

  .section2 h1 {
    max-width: 100%;
  }

  .section3 [class*='col-'] {
    text-align: center;
  }

  .section3 .sec3-1 p,
  .section3 .sec3-3 p {
    max-width: 100%;
    text-align: left;
  }

  .section3 .sec3-1 img {
    width: 75%;
  }

  .section3 .sec3-1,
  .section3 .sec3-2 {
    margin-bottom: 4rem;
  }

  .section3 .sec3-2 img {
    width: 100%;
  }

  .section3 .sec3-3 img {
    width: 100%;
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
  }

  .section3 .sec3-1 h3,
  .section3 .sec3-3 h3 {
    text-align: left;
  }

  .section3 .sec3-3 .btn {
    float: left;
  }

  .section4 h1 {
    font-size: 40px;
  }

  .section4 p {
    padding-left: 0;
  }

  .section5 .coloredTitle {
    max-width: 80%;
    margin: 20px auto;
    font-size: 25px;
  }

  .section7 {
    text-align: center;
  }

  .section7 img:last-child {
    width: 30%;
  }

  .plans-page .title h1 {
    font-size: 50px;
  }

  .file-not-permitted {
    height: unset;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .auth_layout {
    background-image: none;
  }

  .auth_layout .form_layout {
    border: none;
  }

  .home_layout .nav-side {
    display: none;
  }

  .section3 .sec3-2 img {
    width: 100%;
  }

  .section7 {
    text-align: center;
  }

  .section7 img:last-child {
    width: 30%;
  }

  .file-not-permitted {
    height: unset;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .auth_layout {
    background-image: none;
  }

  .home_layout .nav-side {
    display: none;
  }

  .section2 img {
    width: 70%;
  }

  .section2 h1 {
    font-size: 40px;
    max-width: 100%;
  }

  .section3 .sec3-1 p,
  .section3 .sec3-3 p {
    max-width: 100%;
  }

  .section3 .sec3-1 img {
    width: 90%;
  }

  .section3 .sec3-1,
  .section3 .sec3-2 {
    margin-bottom: 4rem;
  }

  .section3 .sec3-3 img {
    width: 100%;
  }

  .section5 .coloredTitle {
    max-width: 100%;
    text-align: center;
  }

  .section3 .sec3-2 img {
    width: 100%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
}
