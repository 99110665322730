body {
  font-family: 'Lato', sans-serif;
}

.ubuntu-font {
  font-family: 'Ubuntu', sans-serif;
}

.cs-p {
  padding: 30px 50px;
}

.coloredTitle {
  font-family: 'Ubuntu', 'Lato', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 50px;
}

.main-color {
  color: #57cc99;
}

.coloredTitle span {
  color: #57cc99;
}

.form-check-input:checked {
  background-color: #57cc99;
  border-color: #57cc99;
}

.form-check-input:focus {
  border-color: #86feb8;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.25rem rgb(134, 254, 184 / 25%);
  box-shadow: 0 0 0 0.25rem rgb(134, 254, 184 / 25%);
}

.bsInputPassword {
  position: relative;
}

.bsInputPassword .form-control.is-invalid {
  background-position: right calc(0.375em + 2.3875rem) center;
}

.form-control.is-valid, .was-validated .form-control:valid {
  background-position: right calc(2.375em + 0.1875rem) center;
}

.bsInputPassword button {
  position: absolute;
  right: 0;
  font-size: 20px;
  z-index: 2;
  bottom: 0;
  top: 0;
  height: 50px;
  margin-top: 30px;
  padding: 0 15px;
}

.bsInputPassword input {
  padding-right: 45px;
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #57cc99 !important;
}

.alice-carousel__dots {
  margin-top: 0 !important;
}

.modal-content {
  border-radius: 20px;
}

.auth_layout {
  background-color: #57cc99;
  background-image: url('https://cdn.1cdn.app/application/CDN/cdn_deliver_fast.svg');
  background-repeat: no-repeat;
  background-position: 100px center;
  background-size: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100vh - 82px);
  padding: 3rem 0;
}

.auth_layout .form_layout {
  background-color: #fff;
  border: 3px solid #000;
  border-radius: 24px;
  padding: 24px;
}

.auth_layout .form_layout .form-label {
  font-weight: 400;
}

.auth_layout .form_layout .form-control {
  height: 50px;
  border-radius: 12px;
  background-color: transparent;
  border-color: #000;
  border-width: 2px;
}

.auth_layout .form_layout .form-check-input {
  background-color: transparent;
  border-color: #000;
}

.auth_layout .form_layout .form-check-input:checked {
  background-color: #000;
}

.auth_layout .form_layout .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.auth_layout .form_layout .auth-btn {
  background-color: rgb(0, 0, 0);
  color: rgba(255, 255, 255, 1);
  border: none;
}

.home_layout {
  background-color: #eff8ef;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.home_layout .nav-side {
  -ms-flex-preferred-size: 10%;
  flex-basis: 10%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.home_layout .nav-side.contact {
  background-color: #38a3a5;
}

.home_layout .nav-side.plan {
  background-color: #57cc99;
}

.home_layout .nav-side.home {
  background-color: #5786cc;
}

.home_layout .main-content {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}

.home_layout .nav-side span {
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 25px;
  position: fixed;
  bottom: 99px;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  min-width: 88px;
}

.home_layout .nav-side span:hover {
  color: #fff;
}

.home_layout_nav {
  padding: 15px 50px;
  position: sticky;
  top: 0;
  background: #eff8ef;
  z-index: 2;
}

.home_layout_nav.isAuthPage {
  background-color: #fff;
}

.home_layout_nav .brand {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.home_layout_nav .brand a {
  font-size: 30px;
  text-decoration: none;
  color: #0c271b;
}

.home_layout_nav .brand p {
  margin-left: 30px;
  color: #777c79;
  display: inline-block;
  max-width: 150px;
  text-align: center;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 0;
}

.home_layout_nav .brand a img {
  width: 50px;
  margin-right: 10px;
}

.home_layout_nav .actions .btn-auth {
  background-color: #57cc99;
  color: #fff;
  font-size: 16px;
}

.home_layout_nav .actions .btn-auth svg {
  margin-left: 10px;
}

.home_layout_nav .actions .tablet-menu {
  background-color: #57cc99;
  width: 250px;
  right: 10px;
  padding: 10px;
  border-radius: 20px 5px 20px 20px;
  top: 50px;
  z-index: 2;
}

.home_layout_nav .actions .tablet-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.home_layout_nav .actions button:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.home_layout_nav .actions .tablet-menu ul li:not(:last-child) {
  margin-bottom: 10px;
}

.home_layout_nav .actions .tablet-menu ul li a:not(.btn) {
  color: #0c271b;
  text-align: left;
  padding: 10px;
  display: block;
  text-decoration: none;
  font-weight: 700;
}

.home_layout_nav .actions .tablet-menu ul:first-child li:nth-child(-n + 3) {
  border-bottom: 1px solid #444;
}

.offcanvas {
  background-color: #57cc99;
}

.offcanvas-header {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.offcanvas-header button {
  font-size: 25px;
}

.home_layout_footer {
  padding: 30px 50px;
  background-color: #227a75;
}

.home_layout_footer .brand {
  font-size: 30px;
  text-decoration: none;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.home_layout_footer .brand img {
  width: 50px;
  margin-right: 10px;
}

.home_layout_footer .menus p {
  color: #e6e6e6;
}

.home_layout_footer .menus ul li:not(:last-child) {
  margin-bottom: 20px;
}

.home_layout_footer .menus ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: 400;
  font-size: 20px;
}

.home_layout_footer .extra-footer p {
  color: #fff;
}

.section1 {
  min-height: calc(100vh - 112px);
  position: relative;
  padding-top: 40px;
  display: flex;
  flex-flow: column;
  justify-content: center;
}

.section1 h1 {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 55px;
  line-height: 69px;
}

.section1 h1 span {
  color: #57cc99;
}

.section1 p {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 31px;
  color: #777C79;
  width: 477px;
}

.section1 .btn {
  border-radius: 10px;
}

.section1 ul li {
  display: flex;
  align-items: center;
}

.section1 ul li span {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #323433;
}

.section1 .guide {
  position: absolute;
  bottom: 2rem;
  right: 4rem;
}

.section2 {
  min-height: 100vh;
}

.section2 h2 {
  font-family: 'Ubuntu', 'Lato', sans-serif;
  font-weight: 400;
  max-width: 75%;
}

.section2 h2 span {
  color: #57cc99;
}

.section3 {
  background-color: #22577a;
}

.section3 .sec3-1 img {
  width: 75%;
}

.section3 .sec3-1 .col-lg-5 {
  text-align: center;
}

.section3 .sec3-1 h3 {
  font-family: 'Ubuntu', 'Lato', sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}

.section3 .sec3-1 h3 span {
  color: #57cc99;
  margin-right: 5px;
}

.section3 .sec3-1 p {
  color: #e6e6e6;
  max-width: 50%;
}

.section3 .sec3-2 h3 {
  font-family: 'Ubuntu', 'Lato', sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 30px;
}

.section3 .sec3-2 h3 span {
  color: #57cc99;
}

.section3 .sec3-2 img {
  width: 65%;
}

.section3 .sec3-3 img {
  width: 75%;
}

.section3 .sec3-3 h3 {
  font-family: 'Ubuntu', 'Lato', sans-serif;
  font-weight: 400;
  color: #fff;
  margin-bottom: 20px;
}

.section3 .sec3-3 h3 span {
  color: #57cc99;
  margin-right: 5px;
}

.section3 .sec3-3 p {
  color: #e6e6e6;
  max-width: 50%;
}

.section3 .sec3-3 .btn-outline-white {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}

.section3 .sec3-3 .btn svg {
  margin-left: 10px;
}

.section3 .sec3-1,
.section3 .sec3-2 {
  margin-bottom: 5rem;
}

.section4 h3 {
  font-family: 'Ubuntu', 'Lato', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 50px;
}

.section4 p {
  padding-left: 75px;
  color: #777c79;
}

.section5 li p {
  color: #0c271b;
  font-weight: bold;
}

.section5 li p small {
  font-size: 20px;
  font-weight: lighter;
}

.section5 .coloredTitle {
  font-family: 'Ubuntu', 'Lato', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
  font-size: 50px;
}

.section5 .coloredTitle span {
  color: #57cc99;
}

.section5 .coloredTitle {
  max-width: 50%;
  margin: 2em auto 1em auto;
}

.section5 .people {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  border-radius: 15px;
}

.section5 .people:hover,
.section5 .people.active {
  background-color: #fff;
}

.section5 .people h4 {
  color: #575757;
  font-size: 18px;
}

.section5 .people p {
  color: #a3a3a3;
}

.section5 .people small {
  color: #a3a3a3;
  font-size: 12px;
}

.section6 .cs-accordion-item h5 {
  color: #0c271b;
}

.section6 .cs-accordion-item h5 span {
  color: #57cc99;
}

.section6 .cs-accordion-item:not(:last-child) {
  margin-bottom: 30px;
}

.section6 .cs-accordion-item svg {
  width: 20px;
}

.section6 .cs-accordion-item p {
  color: #777c79;
}

.section7 {
  background-color: #22577a;
}

.section7.cs-p {
  padding: 5rem 2rem;
}

.section7 p {
  color: #e6e6e6;
}

.plans-page .planCard {
  border: 2px solid #000;
  padding: 29px 34px;
  border-radius: 25px;
}

.plans-page .planCard.active,
.plans-page .planCard:hover {
  border: 2px solid #fff;
  background-color: #333;
  color: #fff;
}

.plans-page .planCard svg {
  margin-right: 10px;
}

.plans-page .planCard.active svg path,
.plans-page .planCard:hover svg path {
  fill: #fff;
}

.plans-page .planCard.active a.btn-dark,
.plans-page .planCard:hover a.btn-dark,
.plans-page .planCard:hover .cs-plan-badge {
  background-color: #f9fafb;
  border-color: #f9fafb;
  color: #000;
}

.plans-page .planCard:hover .cs-plan-badge svg path {
  fill: #000;
}

.plans-page .title h1 {
  font-size: 100px;
  font-family: 'Ubuntu', 'Lato', sans-serif;
  font-weight: 400;
}

.plans-page .title p {
  color: #777c79;
}

.plans-page .planCard .featuresList li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.plans-page .planCard .featuresList li:not(:last-child) {
  margin-bottom: 10px;
}

.plans-page .planCard .featuresList li svg {
  width: 45px;
  min-width: 45px;
}

.plans-page .planCard .cl1 {
  font-family: 'Ubuntu', sans-serif;
}

.plans-page .planCard .cl2 ul {
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  -webkit-column-gap: 30px;
  -moz-column-gap: 30px;
  column-gap: 30px;
}

.plans-page .planCard .cl2 ul li:nth-child(odd) {
  margin-bottom: 20px;
}

.plans-page .form-control {
  min-height: 45px;
  background-color: transparent;
  border-color: #000;
  border-radius: 15px;
}

.plans-page .form-control::-webkit-input-placeholder {
  color: #000;
}

.plans-page .form-control::-moz-placeholder {
  color: #000;
}

.plans-page .form-control:-ms-input-placeholder {
  color: #000;
}

.plans-page .form-control::-ms-input-placeholder {
  color: #000;
}

.plans-page .form-control::placeholder {
  color: #000;
}

.plans-page .form-control:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.uploader {
  height: 150px;
  background-color: #eeeeee;
  border-radius: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #a3a3a3;
  border: 3px dashed #a7a7a7;
  margin-bottom: 20px;
  text-align: center;
}

.cdn-menu-mobile .bodyContent {
  height: calc(100% - 100px);
  padding: 0 20px;
}

.cdn-menu-mobile .navItems a {
  font-size: 30px;
  font-weight: 500;
}

.cdn-menu-mobile .navItems li {
  border-bottom: 1px solid #444;
}

.cs-switch {
  display: inline-block;
  border: 2px solid black;
  border-radius: 50rem !important;
}

.cs-switch .btn-link {
  color: black;
  text-decoration: none;
}

.cs-plan-badge {
  background-color: #000;
  border-radius: 50rem;
  padding: 2px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
}

.planCard.active .cs-plan-badge {
  background-color: #fff;
  color: #000;
}

.planCard.active .cs-plan-badge svg path {
  fill: #000;
}

.file-not-permitted {
  height: calc(100vh - 81.65px);
  background-color: #eff8ef;
}

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap');

.file-not-permitted h1 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 75px;
  font-weight: 400;
}

.reactour__popover span {
  background-color: #57cc99 !important;
}