/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {

  .list-files thead tr th:nth-child(4),
  .list-files thead tr th:nth-child(5),
  .list-files thead tr th:nth-child(6),
  .list-files thead tr th:nth-child(7),
  .list-files tbody tr td:nth-child(4),
  .list-files tbody tr td:nth-child(5),
  .list-files tbody tr td:nth-child(6),
  .list-files tbody tr td:nth-child(7) {
    display: none;
  }

  .alice-carousel {
    max-width: 290px;
  }

  .dash-layout {
    min-height: 100vh;
  }

  .dl-content {
    padding: 0;
    margin-left: 0;
  }

  .dl-content main {
    min-height: 100vh;
    border-radius: 0;
    padding: 10px;
  }

  .dl-sidebar {
    height: unset;
    position: unset;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    display: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: unset;
    flex-flow: unset;
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    max-width: unset;
    min-width: unset;
    width: unset;
  }

  .dl-content .routes {
    height: calc(100vh - 140px);
  }

  .dl-shadow-box {
    border-radius: 10px;
    padding: 10px;
  }

  .dl-service-details li p {
    display: none;
  }

  .groups-table thead tr th:nth-child(1),
  .groups-table tbody tr td:nth-child(1) {
    display: none;
  }

  .dropzone-ui .dz-ui-header {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .dl-table.billing-table thead tr th {
    min-width: 130px;
  }

  .swagger-infos-list li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .swagger-infos-list li .title h5 {
    margin-bottom: 10px;
  }

  .billing-group-btn button:first-child,
  .billing-group-btn button:last-child {
    border-radius: 0;
  }

  .btn-iconic i {
    margin: 0 !important;
  }

  .btn-iconic span {
    display: none;
  }

  .dl-content .routes .row {
    margin-left: 0;
    margin-right: 0;
  }

  .dl-sidebar .upgrade-plan-btn {
    margin-left: inherit;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .alice-carousel {
    max-width: 767px;
  }

  .dash-layout {
    min-height: 100vh;
  }

  .dl-content {
    padding: 0;
    margin-left: 0;
  }

  .dl-content main {
    min-height: 100vh;
    border-radius: 0;
    padding: 10px;
  }

  .dl-content .routes {
    height: calc(100vh - 115px);
  }

  .dl-sidebar {
    height: unset;
    position: unset;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    display: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: unset;
    flex-flow: unset;
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    max-width: unset;
    min-width: unset;
    width: unset;
  }

  .dl-shadow-box {
    border-radius: 10px;
    padding: 10px;
  }

  .dl-service-details li p {
    display: none;
  }

  .groups-table thead tr th:nth-child(1),
  .groups-table tbody tr td:nth-child(1) {
    display: none;
  }

  .dl-sidebar .upgrade-plan-btn {
    margin-left: inherit;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .alice-carousel {
    max-width: 991.98px;
  }

  .dash-layout {
    min-height: 100vh;
  }

  .dl-content {
    padding: 10px;
    margin-left: 0;
  }

  .dl-content main {
    min-height: 95vh;
    border-radius: 30px;
    padding: 15px 10px;
  }

  .dl-sidebar {
    height: unset;
    position: unset;
    left: unset;
    right: unset;
    top: unset;
    bottom: unset;
    display: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: unset;
    flex-flow: unset;
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
    -webkit-box-flex: unset;
    -ms-flex: unset;
    flex: unset;
    max-width: unset;
    min-width: unset;
    width: unset;
  }

  .dl-service-details li p {
    display: none;
  }

  .service-avatar .dl-shadow-box {
    width: 100%;
  }

  .dl-service-status-card svg {
    width: 130px;
  }

  .groups-table thead tr th:nth-child(1),
  .groups-table tbody tr td:nth-child(1) {
    display: none;
  }

  .dl-sidebar .upgrade-plan-btn {
    margin-left: inherit;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .dash-layout {
    min-height: 100vh;
  }

  .dl-content {
    padding: 10px;
    margin-left: 0;
  }

  .dl-content main {
    min-height: 95vh;
    border-radius: 30px;
    padding: 15px 10px;
  }

  .dl-service-details li p {
    display: none;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1400px) {
  .dl-sidebar .dl-sidebar-menu li a {
    padding: 15px 30px;
  }
}